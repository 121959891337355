import { environment } from './environment';

const constants = {
  serverURL: '/api/',
  azure: {
    blob: {
      account: environment.azure.account,
      sasUrl: environment.azure.sasUrl,
      container: 'xray',
      dicomContainer: 'dicom',
      reportsContainer: 'reports',
      dicomPreviewContainer: 'dicom-preview',
      billsContainer:'bills',
    },
  },
  questions: [
    {
      id: 0,
      question: 'How to uploads a new scan and send for reporting on Telefi.ai?',
      answer: `Choose the \`Reports\` tab, click on the \`Upload Scan\`, Add XRAY, enter patient details in the search box and Send for Reporting. 

Or

Choose the Upload tab, Add XRAY, enter patient details in the search box and Send for Reporting.`,
    },
    {
      id: 1,
      question: 'How to ensure that a scan is uploaded?',
      answer: `In the Reports tab each and every uploaded scan is displayed.

Please press \`+\` icon at the beginning of the patient row to view complete details of the uploaded scan.`,
    },
    {
      id: 2,
      question: 'How to mark a scan as Urgent for faster TAT?',
      answer: `Tick \`Urgent (optional)\` check box while entering patient details in Upload tab.

Once a scan is marked as Urgent it will be highlighted in the Reports tab.`,
    },
    {
      id: 3,
      question: 'How to edit patient details after sending the scan for reporting?',
      answer: `In the Reports tab each and every uploaded scan is displayed. Each uploaded scan has \`…\` icon at the right most corner.

Please hover over \`…\` icon on the desired patient scan and select the Edit option. Make the required changes in the Edit Report popup box and press ok.`,
    },
    {
      id: 4,
      question: 'How to delete a wrongly uploaded scan?',
      answer: `In the Reports tab each and every uploaded scan is displayed. Each uploaded scan has \`…\` icon at the right most corner.

Please hover over \`…\` icon on the desired patient scan and select the Delete option. Please ensure that the scan has to be deleted in the Delete Study popup box and press ok.

> **Please Note:**
>
> Once a scan is deleted it cannot be retrieved.
> Delete option is enabled only for 5 min after uploading the scan or before a radiologist starts reporting the scan. Whichever is the earliest.`,
    },
    {
      id: 5,
      question: 'What is the time limit to delete a wrongly uploaded scan?',
      answer: `A wrongly uploaded scan can be deleted within 5 min after uploading the scan or before a radiologist starts reporting the scan. Whichever is the earliest.`,
    },
    {
      id: 6,
      question: 'How to download the radiologist report?',
      answer: `In the Reports tab, download the report from the Reports column. Please click the "arrow" \`˅\` icon to download the report in pdf format and \`…\` icon to download the report in editable word format.`,
    },
  ],
  email: '',
  phone: '',
  billing: {
    cin: 'U36999MH2017PTC303364',
  },
};

export { constants };

export const viewerUrlV3 = "https://viewer3.telefi.ai";

export const ohifViewerLitePublicUrl = '/v';

export const publicRoutes = ['/al', '/report-check', ohifViewerLitePublicUrl, '/login'];

export const radsmartDomain = "https://live.radsmart.in";
export const telefiDomain =   "https://app.telefi.ai";
// export const radsmartDomain = "https://stage.radsmart.in"; // "https://live.radsmart.in"
// export const telefiDomain =  "https://stage2.radsmart.in"; // "https://app.telefi.ai"